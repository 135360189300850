import { Component, Input } from "@angular/core";
import type {MyCardComponent} from "../home-content/home-content.component";
import {clippingParents} from "@popperjs/core";


@Component({
  selector: 'app-scrollview-card',
  templateUrl: './scrollview-card.component.html',
  styleUrls: ['./scrollview-card.component.css']
})
export class ScrollviewCardComponent {
  @Input() public card: MyCardComponent;

  protected readonly clippingParents = clippingParents;
}
