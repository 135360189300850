import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import {ParentsComponent} from "./pages/parents/parents.component";
import {ProvidersComponent} from "./pages/providers/providers.component";
import {AdvocatesComponent} from "./pages/advocates/advocates.component";
import {LegislatorsComponent} from "./pages/legislators/legislators.component";
import {PayorsComponent} from "./pages/payors/payors.component";
import {EducatorsComponent} from "./pages/educators/educators.component";

const routes: Routes = [
  {
    path: 'educators',
    component: EducatorsComponent,
  },  {
    path: 'payors',
    component: PayorsComponent,
  },
  {
    path: 'parents',
    component: ParentsComponent,
  },
  {
    path: 'providers',
    component: ProvidersComponent,
  },
  {
    path: 'legislators',
    component: LegislatorsComponent,
  },
  {
    path: 'advocates',
    component: AdvocatesComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-api',
    component: ExternalApiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
