import { Component, ViewEncapsulation } from "@angular/core";

export interface MyComment {
  likes: number;
  text: string;
}

export interface MyCardComponent {
  thumbnailSrc: string;
  headerTitle: string;
  headerSubtitle: string;
  commentsExpanded: boolean;
  postLiked: boolean;
  comments: Array<MyComment>;
  newCommentTextValue: string;
  postLikes: number;
  scrollViewItems: Array<Record<string, unknown>>;
  audienceType?: string;
  pageSection?: string
}

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class HomeContentComponent {
  // this would be a database query to get the content:
  public scrollViewCards: Array<MyCardComponent> = [
    {
      audienceType: "Parent",
      pageSection: "/parents",
      thumbnailSrc:
          "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rila_lakes.jpg",
      headerTitle: "I Am A Parent",
      headerSubtitle: "Resources for parents seeking services or wanting to learn more about Applied Behavior Analysis for their child",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 962,
      scrollViewItems: [
        {
          url: "assets/Family.jpg",
        },
        // {
        //   url: "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/pamporovo.jpg",
        // },
        // {
        //   url: "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/camping.jpg",
        // },
      ],
    },
    {
      audienceType: "Provider",
      pageSection: "/providers",
      thumbnailSrc:
          "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/bg_flag.jpg",
      headerTitle: "I Am A Provider",
      headerSubtitle: "Resources for Providers",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 674,
      scrollViewItems: [
        {
          url: "assets/Therapist_small.jpg",
        },
      ],
    },
    {
      audienceType: "Legislator",
      pageSection: "/legislators",
      thumbnailSrc:
          "assets/capital_tulips.jpg",
      headerTitle: "I Am A Legislator",
      headerSubtitle: "Resources for legislators to learn more about Applied Behavior Analysis and why it is important for you to support it",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 962,
      scrollViewItems: [
        {
          url: "assets/capitol_tulips.jpg",
        },

      ],
    },
    {
      audienceType: "Advocate",
      pageSection: "/advocates",
      thumbnailSrc:
          "assets/FocusGroup_small.jpg",
      headerTitle: "I Am An Advocate",
      headerSubtitle: "Resources for advocates to learn what is needed and how you can help.",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 962,
      scrollViewItems: [
        {
          url: "assets/FocusGroup_small.jpg",
        },

      ],
    },
    {
      audienceType: "Educator",
      pageSection: "/educators",
      thumbnailSrc:
        "assets/FocusGroup_small.jpg",
      headerTitle: "I Am An Educator",
      headerSubtitle: "Resources for school districts, administrators, and teachers",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 962,
      scrollViewItems: [
        {
          url: "assets/Teacher.jpg",
        },

      ],
    },
    {
      audienceType: "Payor",
      pageSection: "/payors",
      thumbnailSrc:
        "assets/FocusGroup_small.jpg",
      headerTitle: "I Am A Payor",
      headerSubtitle: "Resources for insurance payors, plan administrators, Medicaid administrators, etc.",
      commentsExpanded: false,
      postLiked: false,
      comments: [],
      newCommentTextValue: "",
      postLikes: 962,
      scrollViewItems: [
        {
          url: "assets/Payor.jpg",
        },

      ],
    },
  ];
}
