import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router'
import {filter} from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  // title = 'Auth0 Angular SDK Sample';
  title = 'analytics'
  constructor(router: Router) {
    const navEndEvents = router.events.pipe(
      filter(event => event  instanceof NavigationEnd)
    )
    navEndEvents.subscribe((event:NavigationEnd) => {
      gtag('config', 'G-1ZN3XZLH8P',{
        'page_path': event.urlAfterRedirects
      })
    })
  }

}
