import { Component } from '@angular/core';

@Component({
  selector: 'app-payors',
  templateUrl: './payors.component.html',
  styleUrls: ['./payors.component.css']
})
export class PayorsComponent {

}
