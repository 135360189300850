<title>ABA for Me - Home</title>
<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>



<ng-template #loaded>
  <div class="body">
  <h1>Welcome to ABAforMe.org</h1>
  <p>Your all-in-one resource for understanding Applied Behavior Analysis (ABA), its transformative impact on children with Autism Spectrum Disorder (ASD), and how to navigate the challenges and opportunities it presents.</p>

    <app-home-content></app-home-content>
  <h2>Why ABA?</h2>
  <p>ABA is a scientifically validated approach that has been proven to produce meaningful and lasting behavior changes in individuals with ASD. It's a beacon of hope for countless families, offering a pathway to enhanced communication, improved social interactions, and a higher quality of life. Our mission is to bridge the knowledge gap, ensuring everyone – from parents to policymakers – understands the value and potential of ABA.</p>

  <h2>The Heart of Our Movement: Kentucky's Children Deserve Better</h2>
  <p>At the core of our mission is a simple yet profound truth: every child, irrespective of their developmental abilities or economic background, deserves the best chance at a fulfilling life. Kentucky's families, day in and day out, witness the transformative power of ABA. They see their children communicate, connect, and conquer challenges that once seemed insurmountable. Yet, many of these families also confront barriers that hinder access to these life-changing services. Professionals, driven by passion and purpose, find themselves unable to sustain their commitment due to external pressures. It's a heart-wrenching reality where the aspirations of parents clash with systemic challenges. While we acknowledge the complexities faced by agencies and insurers, it's crucial to remember that at the heart of each policy, each rate, and each decision, there's a child yearning to connect, a family hoping to engage, and a community wishing to support. Together, let's reframe this narrative and prioritize our children's futures.</p>

  <h2>Our Plea: Ensuring Access for Every Child</h2>
  <p>The future of countless children with Autism Spectrum Disorder (ASD) in Kentucky hangs in the balance, largely determined by the accessibility and quality of ABA services. Our consistent endeavors to engage the Kentucky Department of Medicaid and their Managed Care Organizations underscore a distressing truth: without a reasonable adjustment in reimbursement rates, we cannot attract or maintain enough qualified ABA providers across our great state. This isn't merely a monetary issue—it's about the real, tangible impact on families and communities. Every delayed rate adjustment signifies another child waiting, another potential unfulfilled, and another family grappling with uncertainty. The children of Kentucky deserve better. They deserve timely, accessible, and top-tier ABA services no matter where they reside in the Commonwealth. Together, we must rally for this cause, ensuring that each child's potential is not limited by systemic barriers.</p>

  <h2>Join the Movement</h2>
  <p>We invite you to explore, learn, and collaborate. Together, we can shape a future where every child has access to the best interventions, where parents feel supported, and where ABA professionals are celebrated for their vital contributions. Dive in and discover the transformative power of ABA.</p>
  </div>


</ng-template>
