import { Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-advocates',
  templateUrl: './advocates.component.html',
  styleUrls: ['./advocates.component.css']
})
export class AdvocatesComponent {
  onePagerURL = '/assets/ABAOnePager.pdf'

}
