<title>ABA for Me - Providers</title>
<h2>Welcome, ABA Providers!</h2>
<p>You play a pivotal role in changing the lives of countless children and families. As dedicated professionals, you deserve resources and support that empower your work. Here's what you need to know, from policy updates to advocating for your services.</p>

<h3>Why We Advocate: The Disparity in Reimbursement Rates</h3>
<p>The very genesis of our movement, ABA Advocates, stems from the tangible challenges faced in collaborating with the Kentucky Department of Medicaid Services and the Managed Care Organizations responsible for serving Kentucky's children on Medicaid. Our attempts to establish equitable reimbursement rates with giants in the commercial insurance sector - Anthem, Humana, United, Aetna, and Cigna - have often met with resistance. These hurdles create a bleak landscape where ABA providers grapple to offer competitive wages to our frontline workers: those dedicated individuals working diligently with children with developmental disabilities every day. Regrettably, we're witnessing these highly trained professionals departing for non-specialized roles, such as retail positions, simply because their compensation does not align with their expertise or the critical nature of their work. Shockingly, since the onset of the COVID pandemic, our operating costs have surged by over 30%, yet our reimbursement rates have remained stagnant. This stagnation contrasts starkly with other sectors in the medical field like physicians, surgeons, and hospitals, which have witnessed historic rate increments. We believe in the value of ABA and its transformative power for children with ASD. It's time our reimbursement structures reflect that belief and ensure a sustainable future for ABA in Kentucky.</p>

<h3>Understanding Reimbursement Challenges</h3>
<p>The sustainability of ABA practices in Kentucky has been significantly impacted by stagnant reimbursement rates. While costs of operations have surged over 30% since the onset of the COVID pandemic, reimbursement rates remain unchanged. This disparity not only affects the viability of practices but also the ability to offer competitive wages to frontline professionals. As a result, many are considering alternative employment, diminishing the pool of qualified ABA providers for our children.</p>

<h3>Navigating Collaboration with Medicaid & MCOs</h3>
<p>Engaging with the Kentucky Department of Medicaid and their Managed Care Organizations (MCOs) can often seem daunting. We're here to help guide you through the intricacies. While we advocate for improved rates, understanding current policies, processes, and avenues for communication is vital. [Link: Download our guide on Navigating Medicaid & MCO collaborations]</p>

<h3>Strengthening Your Practice: Resources & Tools</h3>
<p>Providing top-tier ABA services requires more than just expertise. Access our curated set of tools, guides, and resources designed to help streamline your practice operations, improve patient engagement, and ensure compliance. [Link: Access our Resource Center]</p>

<h3>Join the Movement: Advocate for Change</h3>
<p>Your voice matters. By uniting as a community of ABA providers, we can amplify our concerns and drive meaningful change. Whether it's participating in advocacy events, liaising with legislators, or simply staying informed, every action counts. Together, let’s ensure a brighter future for ABA in Kentucky. [Link: Get involved with ABA Advocates]</p>

<h3>Stay Updated</h3>
<p>The landscape of ABA services is ever-evolving. Subscribe to our newsletter to receive timely updates on policy changes, advocacy efforts, and other pertinent news. [Link: Subscribe to our Newsletter]</p>

