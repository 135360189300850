import { Component } from '@angular/core';

@Component({
  selector: 'app-legislators',
  templateUrl: './legislators.component.html',
  styleUrls: ['./legislators.component.css']
})
export class LegislatorsComponent {
  onePagerURL = '/assets/ABAOnePager.pdf'
}
