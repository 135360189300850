<title>ABA for Me - Legislators</title>
<!--<kendo-pdfviewer [url]="onePagerURL" [tools]=""></kendo-pdfviewer>-->
<body>
<h2>A Call to Our State's Leaders</h2>
<p>To our esteemed legislators, Kentucky's children with ASD and their families are looking to you for hope and action. Their daily struggles are exacerbated by the lack of accessible quality care. It's more than just numbers on a waitlist—it's about futures hanging in the balance.</p>
<p>We implore you to recognize the gravity of this situation. With your influence and legislative efforts, we can increase the availability of ABA providers, streamline the process of diagnostic testing, and ensure that no child is left waiting.</p>
<p>The future of our state's children is in your hands. Let us come together, acknowledge the pressing needs of these families, and commit to creating a Kentucky where every child, irrespective of their developmental challenges, has access to the support and care they so rightly deserve.</p>

<h3>Experience Autism in Our Community: A Call to Engage</h3>
<p>Statistics and policy briefings can convey only so much. To truly grasp the impact of Autism on our community, we invite our esteemed legislators to immerse themselves in the lived experiences of those affected by ASD. By spending time with families, visiting ABA centers, and attending community support meetings, you can witness the challenges, triumphs, and unyielding resilience of these remarkable families. Such firsthand experiences have the potential to transform perspectives, bridging the gap between policy-making and the real-world implications of those policies. We, at ABAforMe.org, are more than willing to facilitate these engagements, ensuring you gain a holistic understanding of Autism's footprint in our community. Take this step and witness the profound difference you can make in the lives of countless Kentucky families.</p>


<h3>Understanding Autism's Prevalence</h3>
<p>Autism Spectrum Disorder (ASD) is rapidly emerging as one of the most prevalent developmental disorders among children. It's reported that 1 in 36 children has been identified with ASD according to the Centers for Disease Control and Prevention (CDC). A large portion of these children are under 18, necessitating early intervention and support to ensure they lead fulfilling, independent lives as they grow older.</p>

<h3>The Everyday Struggles of Parents with Autistic Children</h3>
<p>Parents of children with ASD are often thrust into a world of challenges and responsibilities far beyond the typical demands of parenthood. These challenges include:</p>
<ul>
  <li><strong>Inability to Work:</strong> Many parents are unable to work due to the lack of services available to their child. This places a full-time responsibility of the childs care directly on their sholders, preventing them from entering the workforce. </li>
  <li><strong>Communication Barriers:</strong> Many children with ASD have difficulty in verbal communication, making even simple conversations a challenge.</li>
  <li><strong>Behavioral Concerns:</strong> Episodes of aggressive behavior or self-harm can be both frequent and unpredictable.</li>
  <li><strong>Social Struggles:</strong> Social norms that come naturally to other children might need explicit teaching for those with ASD.</li>
  <li><strong>Sensory Overloads:</strong> Ordinary environments, like a bustling market or a park, can become overwhelming sensory experiences.</li>
  <li><strong>Educational Needs:</strong> Traditional educational methods, even those found in Special Education settings, might not always be effective, requiring specialized teaching approaches. Many school districts across the Commonwealth actively prevent Behavior Analyists and paraprofessionals from entering the school setting for fear of liability issues.</li>
</ul>

<h3>The Unique Challenges Faced by Parents</h3>
<p>Parenting a child with severe Autism Spectrum Disorder (ASD) brings with it a set of distinct challenges that many may not fully grasp. Alongside the daily responsibilities that all parents face, those caring for a child with severe ASD may confront overwhelming emotional, physical, and financial stressors. The unpredictability of behavioral episodes, communication barriers, and sensory sensitivities can demand constant vigilance. Many parents express feelings of isolation, often navigating a world that doesn't fully understand their child's unique needs. The search for effective treatments, specialists, and supportive communities can be exhausting and, at times, disheartening. At ABAforMe.org, we recognize these challenges and aim to offer a supportive space, providing resources and understanding to help alleviate some of these stressors. Remember, you are not alone, and together, we can pave a brighter path for both you and your child.</p>


<h3>The Search for ABA in Kentucky: A Herculean Task</h3>
<p>For Kentucky's families, locating an ABA provider is akin to finding a needle in a haystack. The state faces a dire shortage of ABA providers. Those few providers that are available often have waitlists that stretch for months or even years. This delay is not just for therapeutic services but also for initial diagnostic testing. Every day spent waiting is a day lost in a child's prime developmental years.</p>

<h3>The Rural Challenge: Retaining BCBAs in Kentucky</h3>
<p>The unique topographical and demographic composition of Kentucky, with a majority of its regions classified as rural, exacerbates the challenges faced by families in need of ABA services. Rural settings often present barriers to attracting and retaining Board Certified Behavior Analysts (BCBAs). The reasons vary - from limited professional development opportunities and networking options, to personal preferences and socio-economic factors. This geographic disadvantage means our children in the rural heartland, arguably the ones in most need of localized services, are left with limited or no options. Our commitment to serving every Kentuckian, irrespective of their zip code, calls for targeted legislative strategies to address this particular challenge and ensure equitable distribution of BCBAs across the state.</p>

<h3>The Exodus Dilemma: Kentucky's Struggle to Retain Home-Grown BCBAs</h3>
<p>Kentucky takes pride in its education system, producing qualified Board Certified Behavior Analysts (BCBAs) who are equipped with the skills and dedication to make a genuine difference. Yet, despite our efforts in fostering this talent, we find ourselves in a paradoxical situation. Due to the comparative lower insurance payer and Medicaid rates in our state, ABA practices face insurmountable challenges in competing to hire and retain these professionals. Neighboring states, with their significantly higher pay rates, naturally become more attractive prospects for our freshly minted BCBAs. This results in a drain of our local talent, as they seek greener pastures elsewhere, lured by the promise of better wages and growth opportunities. It's essential to recognize that this isn't merely a matter of financial remuneration – it's about the sustainability of our ABA practices, the well-being of our children in need of ABA services, and the future of our state's potential as a beacon for healthcare professionals.</p>


</body>
